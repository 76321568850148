<template>

    <button v-if="accion.icono && logeado" type="button" class="btn" :class="claseBoton" @click="ejecutarAccion"
        :title="accion.nombre">
        <i :class="accion.icono"></i>
    </button>
    <button v-else-if="!accion.icono && !accion.link && logeado" type="button" class="btn" :class="claseBoton"
        @click="ejecutarAccion">
        {{ accion.nombre }}
    </button>

    <a v-if="accion.link" @click="ejecutarAccion">{{nombredos}}</a>

</template>

<script>
import { PwgsApi } from '../services/PwgsApi.js';
export default {
    props: ['accion', 'datos','nombredos'],
    computed: {
        claseBoton() {
            return [
                'btn-sm btn-' + this.accion.tipo,
                this.accion.class
            ];
        }
    },
    data(){
        return{
            logeado: true,
        }
    },
    methods: {
        async comprobarLog() {
            if (this.accion.nombre == 'Cerrar sesión') {
                const campoId = this.accion.nombreId;
                const id = this.datos[campoId];
                const api = new PwgsApi();
                try {
                    const resp = await api.get('tiene-token?id_usuario=' + id);
                    this.logeado = resp.sesion_activa == 1;
                } catch (error) {
                    if (error == "Error: Token no válido") {
                        api.get('logout');
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                    console.error('Error comprobando el token:', error);
                    this.logeado = false;
                }
            }
        },

        ejecutarAccion() {
            const campoId = this.accion.nombreId;
            const campoIddos = this.accion.nombreIddos;
            const id = this.datos[campoId];
            const iddos = this.datos[campoIddos];
            this.accion.accion(id,iddos);
        }
    },
    mounted() {
        this.comprobarLog();
    }
}
</script>
<template>
    <boton-accion 
        v-for="accion in acciones" 
        :accion="accion"        
        :key="accion" 
        :datos="datos"
        :nombredos="nombredos">
    </boton-accion>
</template>
<script>
import BotonAccion from './BotonAccion.vue'
export default {
    props: [ 'acciones', 'datos','nombredos' ],
    components: {
        'boton-accion': BotonAccion
    }
}
</script>